<template>
  <div class="profile-page mt-5 py-5">

    <section class="section">
      <div class="container">


        <h1>End-User License Agreement</h1>
        <p>Updated at 2023-06-12</p>

        <p>issist hereby grants you access to https://iss.ist (“the Website”) and invites you to purchase the services
          offered here.</p>

        <h1>Definitions and key terms</h1>
        <p>To help explain things as clearly as possible in this Eula, every time any of these terms are referenced, are
          strictly defined as:</p>
        <ul>
          <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify
            your browser, provide analytics, remember information about you such as your language preference or login
            information.
          </li>
          <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to issist, that is
            responsible for your information under this Eula.
          </li>
          <li>Country: where issist or the owners/founders of issist are based, in this case is Ukraine</li>
          <li>Service: refers to the service provided by issist as described in the relative terms (if available) and on
            this platform.
          </li>
          <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and
            others who provide our content or whose products or services we think may interest you.
          </li>
          <li>You: a person or entity that is registered with issist to use the Services.</li>
        </ul>

        <h1>Introduction</h1>
        <p>This End User License Agreement (the “Agreement”) is a binding agreement between you (“End User”,“you” or
          “your”) and (“Company”, “we”, “us” or “our”). This Agreement governs the relationship between you and us, and
          your use of the Company issist. Throughout this Agreement, End User and Company may each be referred to as a
          “Party” or collectively, the “Parties”.</p>
        <p>If you are using the website/app on behalf of your employer or other entity (an “Organisation”) for whose
          benefit you utilise the website/app or who owns or otherwise controls the means through which you utilise or
          access the website/app, then the terms “End User”, “you”, and “your” shall apply collectively to you as an
          individual and to the Organisation. If you use, or purchase a license or to, the website/app on behalf of an
          Organisation, you hereby acknowledge, warrant, and covenant that you have the authority to 1) purchase a
          license to the website/app on behalf of the Organisation; 2) bind the Organisation to the terms of this
          Agreement.</p>
        <p>By downloading, installing, accessing, or using the website/app you: (a) affirm that you have all of the
          necessary permissions and authorisations to access and use the website/app; (b) if you are using the
          website/app pursuant to a license purchased by an organisation, that you are authorised by that organisation
          to access and use the website/app(c) acknowledge that you have read and that you understand this agreement;
          (d) represent that you are of sound mind and of legal age (18 years of age or older) to enter into a binding
          agreement; and (e) accept and agree to be legally bound by the terms and conditions of this agreement.</p>
        <p>If you do not agree to these terms, do not download, install, access, or use the software. if you have
          already downloaded the software, delete it from your computing device.</p>
        <p>The Application is licensed, not sold, to you by issist for use strictly in accordance with the terms of this
          Agreement.</p>

        <h1>License</h1>
        <p>Subject to the terms of this Agreement and, if applicable, those terms provided in the License Agreement,
          issist grants you a limited, non-exclusive, perpetual, revocable, and non-transferable license to:</p>
        <p>(a) download, install and use the Software on one (1) Computing Device per single user license that you have
          purchased and been granted. If you have multiple Computer Devices in which you wish to use the Software, you
          agree to acquire a license for the number of devices you intend to use;</p>
        <p>(b) access, view, and use on such Computing Device the End User Provided Materials made available in or
          otherwise accessible through the Software, strictly in accordance with this Agreement, and any other terms and
          conditions applicable to such End User Provided Materials;</p>
        <p>(c) install and use the trial version of the Software on any number of Computing Devices for a trial period
          of fifteen (15) unique days after installation.</p>
        <p>(d) receive updates and new features that become available during the one (1) year period from the date on
          which you purchased the license to the Software.</p>

        <h1>Restrictions</h1>
        <p>You agree not to, and you will not permit others to:</p>
        <ul>
          <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise
            commercially exploit the Application or make the Application available to any third party.
          </li>
          <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of
            the Application.
          </li>
          <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of issist
            or its affiliates, partners, suppliers or the licensors of the Application.
          </li>
        </ul>

        <h1>Intellectual Property</h1>
        <p>All intellectual property rights, including copyrights, patents, patent disclosures and inventions (whether
          patentable or not), trademarks service marks, trade secrets, know-how and other confidential information,
          trade dress, trade names, logos, corporate names and domain names, together with all of the good will
          associated there with, derivative works and all other rights (collectively, “Intellectual Property Rights”)
          that are part of the Software that are otherwise owned by issist shall always remain the exclusive property of
          issist (or of its suppliers or licensors, if and when applicable). Nothing in this Agreement grants you (or
          any Organisation) a license to Intellectual Property Rights.</p>
        <p>You agree that this is Agreement conveys a limited license to use Intellectual Property Rights, solely as
          part of the Software (and not independently of it), and only for the effective Term of the license granted to
          you hereunder. Accordingly, your use of any of Intellectual Property Rights independently of the Software or
          outside the scope of this Agreement shall be considered an infringement of Intellectual Property Rights. This
          shall not limit, however, any claim issist may have for a breach of contract in the event you breach a term or
          condition of this Agreement. You shall use the highest standard of care to safeguard all Software (including
          all copies thereof) from infringement, misappropriation, theft, misuse or unauthorised access. Except as
          expressly granted in this Agreement, issist reserves and shall retain all rights, title, and interest in the
          Software, including all copyrights and copyrightable subject matter, trademarks and trademark able subject
          matter, patents and patentable subject matter, trade secrets, and other intellectual property rights,
          registered, unregistered, granted, applied-for, or both now in existence or that may be created, relating to
          the thereto.</p>
        <p>You (or the Organisation, if and as applicable) shall retain ownership of all Intellectual Property Rights in
          and to the work products that you create through or with the assistance of the Software.</p>

        <h1>Your Suggestions</h1>
        <p>Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to
          issist with respect to the Application shall remain the sole and exclusive property of issist.</p>
        <p>issist shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in
          any way without any credit or any compensation to you.</p>

        <h1>Modifications to Application</h1>
        <p>issist reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or
          any service to which it connects, with or without notice and without liability to you.</p>

        <h1>Updates to Application</h1>
        <p>issist may from time to time provide enhancements or improvements to the features/ functionality of the
          Application, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates"). </p>
        <p>Updates may modify or delete certain features and/or functionalities of the Application. You agree that
          issist has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular
          features and/or functionalities of the Application to you. </p>
        <p>You further agree that all Updates will be (i) deemed to constitute an integral part of the Application, and
          (ii) subject to the terms and conditions of this Agreement. </p>
        <h1>Term and Termination</h1>
        <p>This Agreement shall remain in effect until terminated by you or issist. </p>
        <p>issist may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement
          with or without prior notice.</p>
        <p>This Agreement will terminate immediately, without prior notice from issist, in the event that you fail to
          comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application
          and all copies thereof from your computer.</p>
        <p>Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the
          Application from your computer.</p>
        <p>Termination of this Agreement will not limit any of rights or remedies at law or in equity in case of breach
          by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>

        <h1>Indemnification</h1>
        <p>You agree to indemnify, defend and hold harmless issist and its officers, directors, employees, agents,
          affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies,
          claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever
          kind, including reasonable attorneys’ fees, arising from or relating to: i) your use or misuse of the
          Software; ii) your failure to comply with any applicable law, regulation, or government directive; iii) your
          breach of this Agreement; or iv) your agreement or relationship with an Organisation (if applicable) or any
          third party. Furthermore, you agree that issist assumes no responsibility for the information or content you
          submit or make available through this Software or the content that is made available to you by third
          parties.</p>

        <h1>No Warranties</h1>
        <p>The Application is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without
          warranty of any kind. To the maximum extent permitted under applicable law, issist, on its own behalf and on
          behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all
          warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all
          implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and
          warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, issist provides no warranty or undertaking, and makes no representation of any
          kind that the Application will meet your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption, meet any performance
          or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
        <p>Without limiting the foregoing, neither issist nor any provider makes any representation or warranty of any
          kind, express or implied: (i) as to the operation or availability of the Application, or the information,
          content, and materials or products included thereon; (ii) that the Application will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through
          the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of
          issist are free of viruses, scripts, trojan horses, worms, malware, time bombs or other harmful
          components.</p>
        <p>Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on
          the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not
          apply to you.</p>

        <h1>Limitation of Liability</h1>
        <p>Notwithstanding any damages that you might incur, the entire liability of issist and any of its suppliers
          under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to
          the amount actually paid by you for the Application.</p>
        <p>To the maximum extent permitted by applicable law, in no event shall issist or its suppliers be liable for
          any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages
          for loss of profits, for loss of data or other information, for business interruption, for personal injury,
          for loss of privacy arising out of or in any way related to the use of or inability to use the Application,
          third-party software and/or third-party hardware used with the Application, or otherwise in connection with
          any provision of this Agreement), even if issist or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose.</p>
        <p>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so
          the above limitation or exclusion may not apply to you.</p>

        <h1>Severability</h1>
        <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
          law and the remaining provisions will continue in full force and effect.</p>

        <h1>Waiver</h1>
        <p>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under
          this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of
          any right or power under this Agreement preclude further exercise of that or any other right granted herein.
          In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of
          this Agreement shall govern.</p>

        <h1>Amendments to this Agreement</h1>
        <p>issist reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a
          revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole discretion.</p>
        <p>By continuing to access or use our Application after any revisions become effective, you agree to be bound by
          the revised terms. If you do not agree to the new terms, you are no longer authorized to use the
          Application.</p>

        <h1>Governing Law</h1>
        <p>The laws of Ukraine, excluding its conflicts of law rules, shall govern this Agreement and your use of the
          Application. Your use of the Application may also be subject to other local, state, national, or international
          laws.</p>

        <h1>Changes to this agreement</h1>
        <p>We reserve the exclusive right to make changes to this Agreement from time to time. Your continued access to
          and use of the website/app constitutes your agreement to be bound by, and your acceptance of, the terms and
          conditions posted at such time. You acknowledge and agree that you accept this Agreement (and any amendments
          thereto) each time you load, access, or use the website/app. Therefore, we encourage you to review this
          Agreement regularly.</p>
        <p>If, within thirty (30) days of us posting changes or amendments to this Agreement, you decide that you do not
          agree to the updated terms, you may withdraw your acceptance to the amended terms by providing us with written
          notice of your withdrawal. Upon providing us with the written notice of the withdrawal of your acceptance, you
          are no longer authorised to access or use the website/app.</p>

        <h1>No Employment or Agency Relationship</h1>
        <p>No provision of this Agreement, or any part of relationship between you and issist, is intended to create nor
          shall they be deemed or construed to create any relationship between you and issist other than that of and end
          user of the website/app and services provided.</p>

        <h1>Equitable Relief</h1>
        <p>You acknowledge and agree that your breach of this Agreement would cause issist irreparable harm for which
          money damages alone would be inadequate. In addition to damages and any other remedies to which issist may be
          entitled, you acknowledge and agree that we may seek injunctive relief to prevent the actual, threatened or
          continued breach of this Agreement.</p>

        <h1>Headings</h1>
        <p>The headings in this Agreement are for reference only and shall not limit the scope of, or otherwise affect,
          the interpretation of this Agreement.</p>

        <h1>Geographic Restrictions</h1>
        <p>The Company is based in Ukraine and provided for access and use primarily by persons located in Ukraine, and
          is maintains compliance with Ukraine laws and regulations. If you use the website/app from outside Ukraine,
          you are solely and exclusively responsible for compliance with local laws.</p>

        <h1>Limitation of Time to File Claims</h1>
        <p>Any cause of action or claim you may have arising out of or relating to this agreement or the website/app
          must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or
          claim is permanently barred.</p>

        <h1>Entire Agreement</h1>
        <p>The Agreement constitutes the entire agreement between you and issist regarding your use of the Application
          and supersedes all prior and contemporaneous written or oral agreements between you and issist.</p>
        <p>You may be subject to additional terms and conditions that apply when you use or purchase other services,
          which issist will provide to you at the time of such use or purchase.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "EULA"
}
</script>

<style scoped>

</style>